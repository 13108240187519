/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Router from 'vue-router';
import hasNoConnection from '@/router/middleware/HasNoConnection';
import middlewarePipeline from '@/router/middlewarePipeline';
import baseRoutes from '@/router/routes/BaseRoutes';
import userAccountRoutes from '@/router/routes/UserAccount';
import moreInfoRoutes from '@/router/routes/MoreInfo';
import orderRoutes from '@/router/routes/Order';
import operatorRoutes from '@/router/routes/Operator';

Vue.use(Router);

const router = new Router({
    mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
    base: process.env.BASE_URL,
    routes: [
        ...baseRoutes,
        ...userAccountRoutes,
        ...moreInfoRoutes,
        ...orderRoutes,
				...operatorRoutes,
        {
            path: '*',
            component: () => import(/* webpackChunkName: 'error' */ '@/views/Errors/NotFound.vue'),
        },
        {
            path: '/error',
            name: 'ServerError',
            component: () => import(/* webpackChunkName: 'error' */ '@/views/Errors/ServerError.vue'),
        },
        {
            path: '/connection-error',
            name: 'ConnectionError',
            component: () => import(/* webpackChunkName: 'error' */ '@/views/Errors/ConnectionError.vue'),
            meta: {
                middleware: [
                    hasNoConnection,
                ],
            },
        },
    ],
});

// Apply middleware pipeline in route navigation guard
router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next();
    }

    const { middleware } = to.meta;

    const context = {
        to, from, next,
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router;
