export default [
	{
		path: '/operator',
		name: 'OperatorIntro',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/Intro.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/received-items',
		name: 'OperatorReceivedItems',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/ReceivedItems.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/open-door',
		name: 'OperatorOpenDoor',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/OpenDoor.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/refill-shelve',
		name: 'OperatorRefillShelve',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/RefillShelve.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/refill-shelve/instruction',
		name: 'OperatorOpenShelveInstruction',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/OpenShelveInstruction.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/refill-shelve/check-inventory',
		name: 'OperatorCheckInventory',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/CheckInventory.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/refill-products',
		name: 'OperatorRefillProducts',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/RefillProducts.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/position-slots',
		name: 'OperatorPositionSlots',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/PositionSlots.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/close-shelve-instruction',
		name: 'OperatorCloseShelveInstruction',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/CloseShelveInstruction.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/confirmation',
		name: 'OperatorConfirmation',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/Confirmation.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/close-door',
		name: 'OperatorCloseDoor',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/CloseDoor.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
	{
		path: '/operator/machine-checks',
		name: 'OperatorMachineChecks',
		component: () => import(/* webpackChunkName: 'operator' */ '@/views/Operator/MachineChecks.vue'),
		meta: {
			// middleware: [
			// 	isConnectedToMachine,
			// ],
		},
	},
];
