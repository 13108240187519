var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"ui-AppButton block rounded-full cursor-pointer",class:{
            'bg-primary-brand shadow-glow-brand-button text-white': _vm.type === 'primary',
            'bg-day-on-surface text-white': _vm.type === 'secondary' && _vm.darkOnly,
            'bg-day-on-primary dark:bg-night-on-surface dark:text-white': _vm.type === 'secondary' && !_vm.darkOnly,
            'bg-secondary-error shadow-glow-red-button text-white': _vm.type === 'danger',
            'bg-day-08dp dark:bg-night-08dp text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled': _vm.type === 'disabled',
            'border-1-5 border-day-on-surface dark:border-night-on-surface': _vm.type === 'ghost',
            'bg-white': _vm.type === 'white',
            'px-8 py-3-5 text-sm leading-5': _vm.size === 'small',
            'px-6 py-3 text-sm leading-5': _vm.size === 'default',
            'px-3 py-2': _vm.size === 'icon',
            'w-full': _vm.width === 'full',
            'w-auto': _vm.width === 'auto',
            'flex items-center justify-center': _vm.icon,
						'opacity-25 pointer-events-none': _vm.disabled
        },attrs:{"aria-label":_vm.$slots.default && _vm.$slots.default.text,"disabled":_vm.type === 'disabled'},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.icon && _vm.iconPosition === 'left')?_c('AppIcon',{staticClass:"ui-AppButton_Icon-left mr-1",attrs:{"icon-name":_vm.icon,"width":_vm.size === 'small' ? 20 : 24,"height":_vm.size === 'small' ? 20 : 24}}):_vm._e(),(_vm.$slots.default)?_c('span',{staticClass:"ui-AppButton_Text font-medium"},[_vm._t("default")],2):_vm._e(),(_vm.icon && _vm.iconPosition === 'right')?_c('AppIcon',{staticClass:"ui-AppButton_Icon-right ml-1",attrs:{"icon-name":_vm.icon,"width":_vm.size === 'small' ? 20 : 24,"height":_vm.size === 'small' ? 20 : 24}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }