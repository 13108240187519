<template>
    <!-- eslint-disable max-len -->
    <button class="ui-AppButton block rounded-full cursor-pointer"
        @click="$emit('click', $event)"
        :aria-label="$slots.default && $slots.default.text"
        :disabled="type === 'disabled'"
        :class="{
            'bg-primary-brand shadow-glow-brand-button text-white': type === 'primary',
            'bg-day-on-surface text-white': type === 'secondary' && darkOnly,
            'bg-day-on-primary dark:bg-night-on-surface dark:text-white': type === 'secondary' && !darkOnly,
            'bg-secondary-error shadow-glow-red-button text-white': type === 'danger',
            'bg-day-08dp dark:bg-night-08dp text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled': type === 'disabled',
            'border-1-5 border-day-on-surface dark:border-night-on-surface': type === 'ghost',
            'bg-white': type === 'white',
            'px-8 py-3-5 text-sm leading-5': size === 'small',
            'px-6 py-3 text-sm leading-5': size === 'default',
            'px-3 py-2': size === 'icon',
            'w-full': width === 'full',
            'w-auto': width === 'auto',
            'flex items-center justify-center': icon,
						'opacity-25 pointer-events-none': disabled
        }">

        <AppIcon v-if="icon && iconPosition === 'left'"
            class="ui-AppButton_Icon-left mr-1"
            :icon-name="icon"
            :width="size === 'small' ? 20 : 24"
            :height="size === 'small' ? 20 : 24" />

        <span v-if="$slots.default" class="ui-AppButton_Text font-medium">
            <slot />
        </span>

        <AppIcon v-if="icon && iconPosition === 'right'"
            class="ui-AppButton_Icon-right ml-1"
            :icon-name="icon"
            :width="size === 'small' ? 20 : 24"
            :height="size === 'small' ? 20 : 24" />
    </button>
</template>

<script>
import AppIcon from '@/components/Interface/AppIcon.vue';

export default {
    name: 'UIAppButton',

    props: {
        type: {
            type: String,
            default: 'primary',
            validator(value) {
                return ['primary', 'secondary', 'danger', 'disabled', 'ghost', 'white'].indexOf(value) !== -1;
            },
        },

        icon: {
            type: String,
        },

        iconPosition: {
            type: String,
            default: 'left',
        },

        width: {
            type: String,
            default: 'auto',
            validator(value) {
                return ['full', 'auto'].indexOf(value) !== -1;
            },
        },

        size: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'icon', 'small'].indexOf(value) !== -1;
            },
        },

				disabled: {
						type: Boolean,
						default: false,
				},

				darkOnly: {
						type: Boolean,
						default: false,
				},
    },

    components: {
        AppIcon,
    },
};
</script>

<style lang="scss" scoped>
.ui-AppButton {}
</style>
